@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap");

/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "DM Serif Text", serif;
}

/* Full-page Centering */
body,
html {
  height: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  background-color: #ffffff;
  padding: 0;
  overflow: hidden;
}

/* Container */
.Welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  width: 100%;
  height: 100%;
  padding: 20px;
  animation: fadeIn 1.5s ease;
  text-align: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Logo */
.Welcome-logo {
  height: 22vmin;
  max-height: 150px;
  pointer-events: none;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header Section */
.Welcome-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  color: #333;
}

/* Welcome Text */
.Welcome-Text h2 {
  font-size: 1.6em;
  color: #4e3f22;
  margin: 10px 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.Welcome-Text h2:first-child {
  font-size: 1.8em;
  color: #333;
}

/* Button Styling */
.Next-Button {
  text-decoration: none;
  display: inline-block;
  padding: 12px 30px;
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  background-color: #d4a017;
  border: none;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.Next-Button:hover {
  background-color: #b8860b;
  transform: scale(1.05);
}

/* Skeleton Loading Styles */
.skeleton-logo,
.skeleton-text-line,
.skeleton-button {
  background-color: #e0e0e0;
  border-radius: 4px;
  animation: pulse 1.5s infinite ease-in-out;
}

.skeleton-logo {
  width: 22vmin;
  max-height: 150px;
  height: 22vmin;
  margin-bottom: 15px;
}

.skeleton-text-line {
  width: 70%;
  height: 1.6em;
  margin: 10px auto;
}

.skeleton-text-line.wide {
  width: 90%;
}

.skeleton-button {
  width: 180px;
  height: 45px;
  margin-top: 20px;
  border-radius: 25px;
}

/* Pulse Animation for Skeleton */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* Media Queries for Responsive Design */

/* Large Tablets and Small Desktops */
@media (max-width: 1024px) {
  .Welcome-logo {
    height: 25vmin;
  }
  .Welcome-Text h2 {
    font-size: 1.4em;
  }
  .Welcome-Text h2:first-child {
    font-size: 1.6em;
  }
  .Next-Button {
    font-size: 1em;
    padding: 10px 24px;
  }
}

/* Portrait Tablets and Large Phones */
@media (max-width: 768px) {
  .Welcome-logo {
    height: 30vmin;
  }
  .Welcome-Text h2 {
    font-size: 1.3em;
  }
  .Welcome-Text h2:first-child {
    font-size: 1.5em;
  }
  .Next-Button {
    font-size: 0.95em;
    padding: 10px 22px;
  }
}

/* Small Phones */
@media (max-width: 480px) {
  .Welcome-logo {
    height: 35vmin;
  }
  .Welcome-Text h2 {
    font-size: 1.2em;
  }
  .Welcome-Text h2:first-child {
    font-size: 1.4em;
  }
  .Next-Button {
    font-size: 0.95em;
    padding: 10px 20px;
  }
}
