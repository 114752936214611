/* Root Container */
.device-integration-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

/* Skeleton Loader Styles */
.logo-skeleton,
.image-skeleton,
.carousel-skeleton,
.text-skeleton,
.button-skeleton {
  background-color: #e0e0e0;
  border-radius: 8px;
  animation: pulse 1.5s infinite ease-in-out;
}

.logo-skeleton {
  width: 150px;
  height: 80px;
}

.image-skeleton {
  width: 100%;
  height: 150px;
  border-radius: 8px;
}

.carousel-skeleton {
  width: 100%;
  height: 200px;
  max-width: 300px;
}

.text-skeleton {
  width: 80%;
  height: 20px;
  margin: 10px auto;
}

.button-skeleton {
  width: 120px;
  height: 40px;
  border-radius: 20px;
  margin-top: 20px;
}

/* Pulse Animation */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

/* Logo Header */
.device-integration-logo-header {
  text-align: center;
  margin-bottom: 20px;
}

.device-integration-logo-image {
  height: 80px;
  width: auto;
  max-width: 150px;
  animation: device-integration-logo-spin 10s linear infinite;
}

@keyframes device-integration-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Desktop Features Layout */
.device-integration-features-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  max-width: 800px;
  margin-top: 20px;
}

/* Individual Feature Card for Desktop */
.device-integration-feature-card {
  width: 220px;
  text-align: center;
  color: #333;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  padding: 15px;
}

.device-integration-feature-card:hover {
  transform: scale(1.05);
}

.device-integration-feature-images {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.device-integration-feature-text {
  margin-top: 10px;
  color: #1a1a1a;
  font-size: 16px;
  font-weight: bold;
}

/* Mobile Carousel */
.device-integration-features-mobile {
  display: none;
}

.device-integration-feature-carousel {
  width: 100%;
  max-width: 300px;
  text-align: center;
}

.device-integration-carousel-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: opacity 0.5s ease;
}

.device-integration-carousel-text {
  font-size: 1.2em;
  color: #4e3f22;
  margin-top: 10px;
  transition: opacity 0.5s ease;
}

/* Fade-in and Fade-out Animation */
.fade-in {
  opacity: 1;
}
.fade-out {
  opacity: 0;
}

/* Button Section */
.device-integration-center-card {
  text-align: center;
  margin-top: 30px;
}

.device-integration-center-card h2 {
  font-size: 1.8em;
  color: #4e3f22;
  margin-bottom: 20px;
}

.device-integration-next-button {
  text-decoration: none;
  display: inline-block;
  padding: 12px 30px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  background-color: #d4a017;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.device-integration-next-button:hover {
  background-color: #b8860b;
  transform: scale(1.05);
}

/* Mobile-Only Styles */
@media only screen and (max-width: 800px) {
  .device-integration-features-desktop {
    display: none;
  }
  .device-integration-features-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    margin-top: 20px;
  }

  .device-integration-root {
    justify-content: center;
    padding: 10px 0;
  }

  .device-integration-feature-carousel,
  .device-integration-feature-card {
    max-width: 90%;
    padding: 10px;
  }

  .device-integration-feature-text {
    font-size: 14px;
  }

  .device-integration-center-card h2 {
    font-size: 1.5em;
  }

  .device-integration-next-button {
    font-size: 1em;
    padding: 10px 24px;
  }

  .device-integration-logo-image {
    height: 60px;
    max-width: 120px;
  }
}
