/* Reset and Global Settings */
* {
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  overflow: hidden;
}

/* Container for the Form Page */
.full-page-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
  color: #000000;
  text-align: center;
  animation: fadeIn 1.5s ease-in-out;
}

/* Fade-in Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(1.02);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Logo with Rotation */
.thinkingaiLogo {
  height: 70px;
  width: 70px;
  margin-bottom: 20px;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Form Title */
.form-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #000000;
  letter-spacing: 1px;
}

/* Form Layout */
.user-form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  max-width: 400px;
  padding: 0 10px;
}

/* Input Label */
.input-label {
  font-size: 1.1em;
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Input Field */
.form-input {
  padding: 10px;
  background: none;
  border: none;
  border-bottom: 2px solid #000000;
  color: #000000;
  font-size: 1em;
  width: 100%;
  text-align: center;
  outline: none;
  transition: border-color 0.3s ease;
}

.form-input:focus {
  border-color: #ffeb3b;
}

/* Checkbox and Privacy Link */
.checkbox-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9em;
  color: #4e3f22;
  cursor: pointer;
}

.form-checkbox {
  transform: scale(1.2);
  margin-right: 10px;
  accent-color: #000000;
}

/* Privacy Link */
.privacy-link {
  color: #4e3f22;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
}

.privacy-link:hover {
  color: #000000;
}

/* Submit Button */
.submit-button {
  padding: 12px 24px;
  font-size: 1em;
  font-weight: bold;
  color: #000000;
  background-color: #ffeb3b;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #000000;
  color: #ffffff;
}

.submit-button:active {
  transform: scale(0.98);
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #ffffff;
  padding: 20px;
  width: 90%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  overflow-y: auto;
  max-height: 80vh;
  font-size: 0.9em;
}

/* Sticky Close Button */
.close-button {
  position: sticky;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  color: #333;
  padding: 10px;
  background-color: #ffffff;
}

.close-button:hover {
  color: #ff0000;
}

/* Modal Content Headings and Text */
.modal-content h2 {
  font-size: 1.3em;
  margin-top: 0;
}

.modal-content h3 {
  font-size: 1.1em;
  margin: 15px 0 10px;
}

.modal-content p,
.modal-content ul {
  margin-bottom: 10px;
  line-height: 1.5;
}

.modal-content ul {
  padding-left: 20px;
  list-style-type: disc;
}

/* Error Popup Overlay */
.error-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Error Popup */
.error-popup {
  background-color: #fff;
  padding: 20px;
  width: 80%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.error-popup p {
  margin: 0 0 15px;
  color: #333;
}

/* Error Popup Buttons */
.error-close-button,
.error-ok-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
}

.error-ok-button {
  background-color: #ffeb3b;
  color: #000;
  padding: 8px 16px;
  font-weight: bold;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.error-ok-button:hover {
  background-color: #000;
  color: #fff;
}

/* Responsive Design for Mobile */
@media only screen and (max-width: 600px) {
  .form-title {
    font-size: 1.8em;
  }

  .modal-content {
    padding: 15px;
    font-size: 0.85em;
  }

  .submit-button {
    font-size: 0.95em;
    padding: 10px 20px;
  }
}

/* Add spacing between elements */
.checkbox-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1em;
  margin-bottom: 20px; /* Add spacing below the checkbox */
  color: #4e3f22;
  cursor: pointer;
}

/* Adjust spacing for the sign-in button */
.google-signin-button {
  margin-top: 20px; /* Add spacing above the button */
  padding: 10px 20px;
  font-size: 1em;
  color: rgb(255, 255, 255);
  background-color: #000000;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.google-signin-button:hover:not(.disabled) {
  background-color: #357ae8;
}

/* Google Logo size */
.google-logo {
  width: 30px;
  height: 30px;
}

/* Add spacing around the Privacy Policy prompt */
.privacy-prompt {
  margin-bottom: 20px; /* Space below the prompt */
  font-size: 1.1em;
  color: #000000;
  text-align: center;
}
