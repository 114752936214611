@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text&display=swap");

/* Global Container */
.root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  box-sizing: border-box;
  overflow: hidden;
}

/* Logo Header */
.logo-header {
  text-align: center;
  margin-bottom: 20px;
  padding-top: 20px;
}

.logo-image {
  height: 80px;
  width: auto;
  max-width: 150px;
  animation: logo-spin 10s linear infinite;
}

.logo-skeleton {
  width: 150px;
  height: 80px;
  background-color: #e0e0e0;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Feature Section */
.features {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.features.loading {
  pointer-events: none;
}

.text-skeleton {
  width: 80%;
  height: 20px;
  background-color: #e0e0e0;
  margin: 10px auto;
  border-radius: 5px;
  animation: pulse 1.5s infinite ease-in-out;
}

.button-skeleton {
  width: 120px;
  height: 40px;
  background-color: #e0e0e0;
  border-radius: 20px;
  margin-top: 20px;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Desktop View */
.features-desktop {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.main-card {
  max-width: 25%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.center-card {
  max-width: 50%;
  text-align: center;
}

.feature-card {
  max-width: 238px;
  width: 100%;
  text-align: center;
  color: #333;
  padding: 10px;
}

.Feature-images {
  width: 100%;
  border-radius: 10px;
  transition: opacity 0.5s ease;
}

.image-skeleton {
  width: 100%;
  height: 150px;
  background-color: #e0e0e0;
  border-radius: 10px;
  animation: pulse 1.5s infinite ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.Feature-Text {
  margin-top: 10px;
  color: #1a1a1a;
  font-size: 16px;
  font-weight: bold;
}

/* Button Styling */
.Next-Button {
  text-decoration: none;
  display: inline-block;
  padding: 12px 30px;
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
  color: #ffffff;
  background-color: #d4a017;
  border: none;
  border-radius: 30px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Next-Button:hover {
  background-color: #b8860b;
  transform: scale(1.05);
}

/* Mobile View */
.features-mobile {
  display: none;
}

@media only screen and (max-width: 800px) {
  /* Hide desktop view on mobile */
  .features-desktop {
    display: none;
  }

  /* Mobile view adjustments */
  .features-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 10px; /* Slight padding to prevent overflow */
  }

  /* Center content vertically and remove padding on .root */
  .root {
    padding-top: 0;
    justify-content: center;
  }

  /* Adjust main card to fit screen width */
  .main-card {
    max-width: 90%;
    padding: 10px;
  }

  /* Adjust logo size */
  .logo-image {
    height: 60px;
    max-width: 120px;
  }

  /* Feature text and button size adjustments */
  .Feature-Text {
    font-size: 14px;
  }

  .Next-Button {
    font-size: 1em;
    padding: 10px 24px;
  }
}

/* Skeleton Animation */
@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
