/* Global Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

/* Full-Screen Layout */
.welcome-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #f9f9f9;
  position: relative;
}

/* Sidebar Styling */
.sidebar {
  width: 250px;
  background-color: #353535;
  color: #f1f1f1;
  display: flex;
  flex-direction: column;
  padding: 15px;
  transition: transform 0.3s ease-in-out;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  z-index: 1001;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: auto;
  scrollbar-width: thin; /* Firefox */
}

/* Custom scrollbar for sidebar */
.sidebar::-webkit-scrollbar {
  width: 8px;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 4px;
}
.sidebar::-webkit-scrollbar-track {
  background-color: #353535;
}

/* Sidebar Visibility Control */
@media (min-width: 800px) {
  .sidebar {
    transform: translateX(0);
  }
}

@media (max-width: 800px) {
  .sidebar {
    transform: translateX(-100%);
  }

  .sidebar.open {
    transform: translateX(0);
  }
}

/* Sidebar Header */
.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-logo {
  height: 40px;
  margin-right: 10px;
}

.sidebar h2 {
  font-size: 1.4em;
  color: #f1f1f1;
}

/* Sidebar Button */
.new-chat-button {
  background-color: #7d7d7d;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 15px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.new-chat-button:hover {
  background-color: #9c9c9c;
}

/* Sidebar Navigation */
.sidebar-nav {
  flex-grow: 1; /* Allows the chat history list to expand */
  overflow-y: auto; /* Makes chat history scrollable */
}
.sidebar-nav ul {
  list-style: none;
  padding-left: 0;
}

/* Individual chat items */
.sidebar-nav li {
  padding: 10px 0;
  cursor: pointer;
  color: #e0e0e0;
  transition: color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sidebar-nav li:hover {
  color: #d1d1d1;
}

/* Three-dot menu button */
.options-button {
  font-size: 1.5em;
  cursor: pointer;
  padding-left: 5px;
}

/* Chat options dropdown */
.chat-options {
  position: absolute;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  margin-top: 5px;
  right: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
}

.chat-options button {
  padding: 8px 12px;
  cursor: pointer;
  border: none;
  background: none;
  text-align: left;
}

.chat-options button:hover {
  background-color: #f2f2f2;
}

/* Rename Modal Centered and Responsive */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1200;
}

.rename-modal {
  width: 90%; /* Mobile-first responsive width */
  max-width: 400px; /* Sets a max width for larger screens */
  background: #fff;
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1201;
  text-align: center;
  animation: fadeInModal 0.3s ease;
}

.rename-modal h3 {
  margin-bottom: 20px;
  color: #333;
}

.rename-modal input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.rename-modal .popup-actions {
  display: flex;
  justify-content: space-between;
}

.rename-modal button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.rename-modal .popup-cancel-button {
  background-color: #e0e0e0;
  color: #333;
}

.rename-modal .popup-action-button {
  background-color: #4e665e;
  color: white;
}

.rename-modal .popup-action-button:hover {
  background-color: #658d7b;
}

@keyframes fadeInModal {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Main Content Area */
.main-chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 250px;
  height: 100%;
  background-color: #ffffff;
  padding-top: 60px;
  overflow-y: auto;
}

@media (max-width: 800px) {
  .main-chat {
    margin-left: 0;
    padding-left: 10px;
    padding-top: 60px;
  }
}

/* Chat Area */
.chat-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Message Bubbles */
.chat-message {
  padding: 12px 18px;
  border-radius: 12px;
  margin-bottom: 10px;
  max-width: 75%;
  word-wrap: break-word;
  transition: background-color 0.3s ease;
}

.chat-message.bot {
  background-color: #dcdcdc;
  color: #353535;
  align-self: flex-start;
  border-radius: 12px 12px 12px 0;
}

.chat-message.user {
  background-color: #e0a96d;
  color: #2b2b2b;
  align-self: flex-end;
  border-radius: 12px 12px 0 12px;
}

/* Loading Indicator */
.typing-indicator {
  background-color: #dcdcdc;
  color: #353535;
  font-style: italic;
  padding: 10px;
  border-radius: 12px;
  align-self: flex-start;
  display: flex;
  align-items: center;
}

/* Chat Input */
.chat-input {
  display: flex;
  padding: 12px;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
  position: sticky;
  bottom: 0;
  z-index: 1002;
}

@media (max-width: 800px) {
  .chat-input {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 10px;
  outline: none;
}

.chat-input button {
  background-color: #7d7d7d;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-input button:hover {
  background-color: #020202;
}

.chat-input button::before {
  content: "↑";
  font-size: 18px;
  color: white;
}

/* Sidebar Toggle Button for Mobile */
.sidebar-toggle {
  display: none;
}

@media (max-width: 800px) {
  .sidebar-toggle {
    display: block;
    position: fixed;
    top: 15px;
    left: 15px;
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1100;
  }

  .sidebar-toggle.hide {
    display: none;
  }

  .sidebar-toggle::before,
  .sidebar-toggle::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 3px;
    background-color: #353535;
    left: 0;
    transition: transform 0.3s;
    border-radius: 3px;
  }

  .sidebar-toggle::before {
    top: 6px;
  }

  .sidebar-toggle::after {
    top: 14px;
  }
}

/* Profile Menu Styling */
.profile-menu-container {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 1100;
}

.profile-icon {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: bold;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.profile-icon:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}

.profile-menu-dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 180px;
  animation: dropdownFade 0.3s ease;
}

.profile-menu-dropdown p {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
  margin: 0;
  color: #353535;
  font-size: 0.9em;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s ease;
}

.profile-menu-dropdown p:hover {
  background-color: #f2f2f2;
}

/* Popup Styling */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease;
  z-index: 1200;
}

.popup-content {
  background: #ffffff;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  animation: scaleIn 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  color: #333;
  cursor: pointer;
}

.popup-content h3 {
  margin-top: 10px;
  color: #333;
}

.popup-content p {
  color: #666;
  margin: 20px 0;
}

.popup-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.popup-cancel-button,
.popup-action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.popup-cancel-button {
  background-color: #f2f2f2;
  color: #333;
}

.popup-action-button {
  background-color: #4e665e;
  color: #fff;
}

.popup-cancel-button:hover {
  background-color: #e0e0e0;
}

.popup-action-button:hover {
  background-color: #658d7b;
}

.suggestions {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.suggestion {
  margin: 0.5rem 0;
  padding: 0.5rem;
  background-color: #e8f4ff;
  border: 1px solid #d0e8ff;
  border-radius: 5px;
}

/* Desktop View (No changes from the original) */
.speech-controls {
  text-align: center;
  margin-top: 1rem;
}

.speech-button {
  background: linear-gradient(90deg, #6a11cb, #2575fc);
  color: white;
  padding: 0.75rem 1.5rem;
  font-size: 1.2rem;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.speech-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.speech-button.listening {
  background: linear-gradient(90deg, #ff512f, #dd2476);
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 0.5rem;
  text-align: center;
}

/* Mobile View Adjustments */
@media (max-width: 768px) {
  .speech-controls {
    position: fixed; /* Keeps the button visible */
    bottom: 80px; /* Position above the input bar */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for centering */
    z-index: 1000; /* Ensure it stays above other elements */
  }

  .speech-button {
    font-size: 1rem; /* Adjust font size for mobile */
    padding: 0.6rem 1rem; /* Smaller padding for compact size */
    border-radius: 20px; /* Adjust border radius */
  }

  .error-message {
    position: absolute;
    bottom: 140px; /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.8rem; /* Smaller font size for mobile */
    color: red;
    text-align: center;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.9);
  }
  to {
    transform: scale(1);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-icon {
  display: inline-block;
  border: 2px solid #ddd;
  border-top: 2px solid #3399cc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
